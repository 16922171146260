

























































import Vue from "vue";
export default Vue.extend({
  data() {
    return {
      cuestionarioA: [
        { title: "Fiebre" },
        { title: "Tos Seca" },
        { title: "Cansancio" },
        { title: "Pérdida del sentido del olfato o del gusto" },
        { title: "Congestión nasal" },
        { title: "Conjuntivitis" },
        { title: "Dolor de garganta" },
        { title: "Dolor de cabeza" },
        { title: "Dolores musculares o articulares" },
        { title: "Erupciones cutáneas" },
        { title: "Náuseas o vómito" },
        { title: "Diarrera" },
        { title: "Escalofríos o vértigo" },
        { title: "Dificultad para respirar o sensación de falta de aire" },
        { title: "Pérdida del apetito" },
        { title: "Confusión" },
        { title: "Dolor o presión en el pecho" },
        { title: "Temperatura alta (mayor a los 38 grados C" },
        {
          title:
            "¿Durante los últimos 15 días tuviste contacto presencial con alguna persona con COVID-19?"
        }
      ],
      cuestionarioB: [
        { title: "Obesidad mórbida" },
        { title: "Enfermedad cardiovascular crónica" },
        { title: "Enfermedad respiratoria crónica moderada o grave" },
        { title: "Diabetes Mellitus Tipo 1 o 2" },
        { title: "Enfermedad renal crónica" },
        { title: "Enfermedad hepática crónica" },
        { title: "VIH" },
        { title: "Personas inmunodeprimidas" },
        { title: "Enfermedades de células falciformes o talasemia" },
        { title: "Trasplante de órganos sólidos o células madres sanguínea" },
        { title: "Enfermedad neurológica crónica" },
        { title: "Síndrome de Down" },
        { title: "Pacientes oncológicos y oncohematológicos" },
        { title: "Tuberculosis activa" },
        { title: "Personas con discapacidad" }
      ]
    };
  }
});
